<template>
  <div class="houseInfoReported">
    <v-list
      ref="list"
      :isAdd="true"
      :border="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :operateColumnWidth="150"
      operateColumnAlign="center"
      :beforeList="beforeList"
      isManySelect
      :selectable="selectable"
    >
      <template #searchSlot>
        <v-select
          :options="tenantList"
          v-model="searchParam.tenantId"
          @change="selectCommunity"
          placeholder="选择社区"
        />
        <v-select
          clearable
          v-if="searchParam.tenantId"
          :options="communityList"
          v-model="searchParam.communityId"
          @change="$refs.list.getList()"
          placeholder="选择小区"
        />

        <v-input label="幢" v-model="searchParam.dr" type="number" />
        <v-input label="单元" v-model="searchParam.dy" type="number" />
        <v-input label="室" v-model="searchParam.room" type="number" />
        <v-select
          clearable
          :options="reportStatusOps"
          v-model="searchParam.status"
          @change="$refs.list.getList()"
          placeholder="请选择上报状态"
        />
      </template>
      <template #headBtnSlot>
        <v-button text="新增" @click="handelAddClick"></v-button>
      </template>
      <template #operateSlot="scope">
        <v-button
          :disabled="!scope.row.houseCode"
          type="text"
          text="删除"
          @click="deleteData(scope.row)"
        />
        <v-button type="text" text="日志" @click="goViewLog(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button
          v-loading.fullscreen.lock="isLoading"
          element-loading-text="拼命上报中"
          text="批量上报"
          :disabled="scope.selectedData.data.length === 0"
          @click="reportData(scope.selectedData, 1)"
        />
        <v-button
          text="全部上报"
          v-loading.fullscreen.lock="isAllLoading"
          element-loading-text="拼命上报中"
          @click="reportData(scope.selectedData, 2)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  communityListUrl,
  authTenantListUrl,
  getReportedHouseInfoList,
  reportedSpaceInfo,
  addAndReportedHouseInfo,
  cancelReportedHouseInfo,
  getTenantList,
} from "./api.js";
import { reportStatusMap, reportStatusOps } from "./map.js";
import { _localStorage } from "@/utils/utils.js";
export default {
  name: "houseInfoReported",
  data() {
    return {
      isLoading: false,
      isAllLoading: false,
      reportStatusOps: reportStatusOps(), //上报状态
      communityList: [], //小区列表
      tenantList: [], //社区列表
      searchParam: {
        tenantId: "",
        communityId: "",
        dr: "",
        dy: "",
        room: "",
        status: "",
      },
      tenantId: "",
      tableUrl: getReportedHouseInfoList,
      headers: [
        {
          prop: "houseCode",
          label: "省编码",
          align: "center",
          formatter: (row, prop) => {
            return row[prop] ? row[prop] : "--";
          },
        },
        {
          prop: "communityName",
          label: "小区",
          align: "center",
        },
        {
          prop: "buildingName",
          label: "楼幢",
          align: "center",
        },
        {
          prop: "unitName",
          label: "单元",
          align: "center",
        },
        {
          prop: "roomName",
          label: "房号",
          align: "center",
        },
        {
          prop: "path",
          label: "地址",
          align: "center",
        },
        {
          prop: "status",
          label: "上报状态",
          align: "center",
          formatter: (row, prop) => {
            return row.houseCode ? "已上报" : "未上报";
          },
        },
      ],
    };
  },
  computed: {},
  created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.tenantId = userInfo.tenantId;
    }
  },
  mounted() {},
  methods: {
    //是否可勾选
    selectable(row) {
      return !row.houseCode;
    },
    //删除
    deleteData(row) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = {
          id: row.id,
          tenantId: this.tenantId,
        };
        this.$axios
          .post(cancelReportedHouseInfo, this.$qs.stringify(params))
          .then((res) => {
            if (res.code == 200) {
              this.$refs.list.getList();
              this.$message.success(res.msg);
            }
          });
      });
    },
    //全部上报or批量上报
    reportData(id, type) {
      let params;
      if (type == 1) {
        this.isLoading = true;
        const ids = id.ids.join(",");
        params = {
          ids: ids,
          tenantId: this.tenantId,
        };
      } else {
        this.isAllLoading = true;
        params = {
          tenantId: this.tenantId,
        };
      }
      this.reportCommonFun(params);
    },
    reportCommonFun(params) {
      this.$axios
        .post(`${reportedSpaceInfo}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.$refs.list.toggleSelection();
            this.$refs.list.getList();
            this.$message.success(res.data);
          }
        })
        .finally(() => {
          this.$refs.list.toggleSelection();
          this.isLoading = false;
          this.isAllLoading = false;
        });
    },
    //获取社区编码列表
    async getTenantList() {
      const params = {
        tenantId: this.tenantId,
      };
      const res = await this.$axios.post(
        getTenantList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        this.tenantList = [];
        const { data } = res;
        data.forEach((ele) => {
          let obj = {};
          obj.label = ele.tenantName;
          obj.value = ele.tenantId;
          this.tenantList.push(obj);
        });
        this.searchParam.tenantId = this.tenantList[0].value;
      }
    },
    //新增
    handelAddClick() {
      this.$router.push({
        name: "zZTSSHouseInfoUpReportEdit",
      });
    },
    async beforeList() {
      await this.getTenantList();
    },
    //选择社区触发
    selectCommunity() {
      this.getCommunityList();
      this.$refs.list.getList();
    },
    //获取小区列表
    async getCommunityList() {
      let params = { tenantId: this.searchParam.tenantId };
      let res = await this.$axios.get(`${communityListUrl}`, { params });
      if (res.code == 200) {
        this.communityList = [];
        if (res.data && res.data.records) {
          res.data.records.forEach((ele) => {
            let obj = {};
            obj.label = ele.name;
            obj.value = ele.spaceId;
            this.communityList.push(obj);
          });
        }
      }
    },
    //获取社区列表
    // async getTenantList() {
    //   const params = {
    //     tenantId: this.tenantId,
    //   };
    //   let res = await this.$axios.get(`${authTenantListUrl}`, { params });
    //   if (res.code == 200) {
    //     this.tenantList = [];
    //     if (res.data) {
    //       res.data.forEach((ele) => {
    //         let obj = {};
    //         obj.label = ele.tenantName;
    //         obj.value = ele.tenantId;
    //         this.tenantList.push(obj);
    //       });
    //       this.searchParam.tenantId = this.tenantList[0].value;
    //     }
    //   }
    // },
    //查看日志
    goViewLog(row) {
      this.$router.push({
        name: "zZTSSHouseInfoUpReportViewLogs",
        query: {
          id: row.id,
          type: 1,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.houseInfoReported {
  box-sizing: border-box;
  height: 100%;
  /deep/ .v-table .opera-btn {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
}
</style>
